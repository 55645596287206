import React from "react";
import styles from "./pages.module.scss";

import Banner from "../components/banner";
import GITHeader from "../components/getInTouch_header";
import GITContactInfo from "../components/getInTouch_contactInfo";

export default () => (
  <div className={styles.Page}>
    <Banner page="contact-linda" />
    <div className={styles.content_container}>
      <GITHeader />
      <GITContactInfo />
    </div>
  </div>
);
