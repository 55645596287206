import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./getInTouch_contactInfo.module.scss";

//only need this in dev
// const GATEWAY_URL = process.env.GATEWAY_URL;
const required = "This field is required";

const GITContactInfo = () => {
  //react-hook-form start
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    reset,
    setError,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      //change to GATEWAY_URL in dev
      await fetch("/api/email", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      reset();
      setSubmitted(true);
    } catch (err) {
      //handle server errors
      setError(
        "submit",
        "submitError",
        `Oops, something went wrong! ${err.message}`
      );
    }
  };

  const showSubmitError = (msg) => (
    <p className={styles.message_error}>{msg}</p>
  );

  const [submitted, setSubmitted] = useState(false);

  const showThankYou = (
    <div className={styles.thankYou_message}>
      <div>
        <h3>MESSAGE SENT</h3>
        <p>Thank you for your message!</p>
        <button onClick={() => setSubmitted(false)}>CLOSE</button>
      </div>
    </div>
  );

  //react-hook-form end

  let handleCancelClick = (e) => {
    e.preventDefault();
    return reset();
  };

  return (
    <div className={styles.GITContactInfo_container}>
      {submitted ? showThankYou : null}
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <h3>CONTACT LINDA</h3>
        <label>
          Name
          <input
            type="text"
            name="name"
            placeholder="Full name"
            ref={register({ required })}
            disabled={isSubmitting}
          />
          {errors.name && (
            <div className={styles.message_error}>{errors.name.message}</div>
          )}
        </label>
        <label>
          Email
          <input
            type="text"
            name="email"
            placeholder="email@email.com"
            ref={register({ required })}
            disabled={isSubmitting}
          />
          {errors.email && (
            <div className={styles.message_error}>{errors.email.message}</div>
          )}
        </label>
        <label>
          Message
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            placeholder="Type your message here"
            ref={register({ required })}
            disabled={isSubmitting}
          ></textarea>
          {errors.message && (
            <div className={styles.message_error}>{errors.message.message}</div>
          )}
        </label>
        <div className={styles.buttons_container}>
          <input
            type="submit"
            value="SUBMIT"
            className={styles.form_button}
            disabled={isSubmitting}
          />
          <input
            type="button"
            value="CANCEL"
            className={styles.form_button}
            onClick={handleCancelClick}
            disabled={isSubmitting}
          />
        </div>
        {errors && errors.submit && showSubmitError(errors.submit.message)}
      </form>

      <ul className={styles.other_contactInfo}>
        <li>
          <h3>EMAIL</h3>
          <a href="mailto: repichiyama@gmail.com">RepIchiyama@gmail.com</a>
        </li>
        <li>
          <h3>PHONE</h3>
          <a href="tel:+18083924547">(808) 392-4547</a>
        </li>
        <li>
          <h3>MAIL</h3>
          <address>
            <p>Friends of Linda Ichiyama</p>
            <p>P.O. Box 1707</p>
            <p>Aiea, HI 96701</p>
          </address>
        </li>
      </ul>
    </div>
  );
};

export default GITContactInfo;
