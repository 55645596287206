import React from "react";
import styles from "./getInTouch_header.module.scss";

const GITHeader = () => {
  return (
    <div className={styles.Header}>
      <h3>YOUR INPUT IS VALUABLE</h3>
      <p>
        I welcome your questions and comments and look forward to hearing from
        you. You can get in touch with me by using the convenient form below,
        sending me an email, or calling me.
      </p>
    </div>
  );
};

export default GITHeader;
